import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import * as yup from 'yup'

import Box from '../components/Box';
import Text from '../components/Text';
import Input from '../components/Input';
import Button from '../components/Button';
import Container from '../components/Container';
import { responsive } from '../components/ThemeProvider/theme';

import Layout from './Layout'
import useFirebaseFunctions from '../utils/useFirebaseFunctions';

const Subscribe = () => {
  const subscribe = useFirebaseFunctions('subscribe')
  const [submitted, setSubmitted] = useState()
  return (
    <Layout height="100vh">
      <Container maxWidth="29.625rem" pt={responsive('1.5em', '3.25em')}>
        <Text lineHeight="1" fontSize={responsive('1.625em', '1.5em')}>關注我們</Text>
        <Box mt={responsive('0.5rem', '0.75rem')} fontSize={responsive('0.875em', '1.125em')}>
          <Text.Bold>關注我們就能搶先收到最新好玩的數！</Text.Bold>
          <Text lineHeight="1.77" mt="0.5rem" fontWeight="500">別擔心，我們只會在有重要的數、或新計畫上線時以信件通知你！如果你不再想收到信，也可以隨時來信取消關注！</Text>
        </Box>
        <Box mt={responsive('0.875em', '2em')}>
        <Formik
            initialValues={{ email: '' }}
            validationSchema={yup.object({
              email: yup.string().email().required('此欄位必填'),
            })}
            onSubmit={async (values, formikBag) => {
              try {
                await subscribe({ email: values.email })
                setSubmitted(true)
              } catch (e) {
                console.error(e)
              }
              formikBag.setSubmitting(false)
            }}
          >
            {({ isSubmitting, handleChange, handleBlur }) => submitted ? <Text.P my="1em">成功送出，請至信箱收取確認信</Text.P> : (
              <Form>
                <Text fontSize={responsive('0.75em', '0.875em')} fontWeight="500">Email</Text>
                <Input
                  name="email"
                  type="email"
                  borderRadius="0.625em"
                  border="3px solid"
                  borderColor="black"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Box textAlign="center" mt={responsive('1.375rem', '2.625rem')}>
                  <Button.Yellow
                    width="100%"
                    type="submit"
                    isLoading={isSubmitting}
                  >開始關注</Button.Yellow>
                </Box>
                <Text.Bold mt={responsive('0.5rem', '1.25rem')} fontSize={responsive('0.75em', '0.875em')}>
                  ＊送出後，我們將發出通知信，請檢查促銷信件夾，確保我們未來的互動暢通無阻。
                </Text.Bold>
              </Form>
            )}
          </Formik>
        </Box>
      </Container>
    </Layout>
  )
}

export default Subscribe
